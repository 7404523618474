$header_height: 165px;

.br-header {
  --bs-gutter-x: 20px;
  position: relative;
  background-color: var(--color-four);
  padding: 21px 0 15px;
  border-bottom: 10px solid var(--color-two);

  @media (min-width: 992px) {
    border: none;
    padding: 50px 0;
    height: $header_height;
  }
}

.br-header__logo-link {
  display: inline-block;
  margin: 0 auto;
  text-align: center;
  max-width: 160px;
  margin-bottom: 20px;

  @media (min-width: 992px) {
    margin: 0 80px 0 0;
  }
}

.br-header-group {
  text-align: center;
  @media (min-width: 992px) {
    display: flex;
    align-items: center;
  }
}

.br-header-search {
  width: 100%;

  @media (min-width: 992px) {
    max-width: 510px;
  }

  form {
    width: 100%;
    position: relative;

    button {
      background: transparent;
      cursor: pointer;
      border: none;
      padding: 5px;
      height: 100%;
      width: 40px;
      position: absolute;
      right: 0;
      border-top-right-radius: 2px;
      border-bottom-right-radius: 2px;
      &:focus {
        outline: 0;
      }
    }
  }
  .br-input {
    width: 100%;
    padding-right: 45px;
  }
}

.br-btn-account {
  border-radius: 3px;
  cursor: pointer;
  padding: 6px 14px;
  background-color: white;
  border: none;
  color: #0c2a3c;
  margin-left: auto;
  text-decoration: none;
}

.br-menu {
  --bs-gutter-x: 20px;
  position: fixed;
  background-color: #0c2a3c;
  color: white;
  top: 90px;
  height: calc(100% - 90px);
  width: 100%;
  overflow-y: auto;
  z-index: 10;
  display: none;

  &.active {
    display: block;
  }

  @media (min-width: 992px) {
    position: relative;
    height: max-content;
    top: 0;
    display: block !important;
  }
}

.br-main-nav {
  background-color: #f7ad15;
  color: #0c2a3c;
  padding: 24px 0;

  ul {
    margin: 0;
    padding: 0;
    list-style: none;

    li + li {
      margin-top: 26px;
    }
  }

  @media (min-width: 992px) {
    padding: 5px 0;

    ul {
      display: flex;
      align-items: center;

      li + li {
        margin-top: 0;
        margin-left: 10px;
      }
    }
  }
}

.br-main-nav__link {
  font-weight: bold;
  font-size: rem(24px);
  text-decoration: none;
  color: var(--color-four);
  padding: 4px 6px;
  border-radius: 3px;
  line-height: 1;
  margin-left: -6px;

  transition: 200ms ease;

  &:hover {
    background-color: #ffcf00;
  }

  &:focus {
    background-color: transparent;
    outline: 0;
    box-shadow: inset 0 0 0 1px white;
  }

  &:disabled,
  &[disabled] {
    color: #ffcf00;
    box-shadow: none;
    &:hover {
      background-color: transparent;
      cursor: default;
    }
  }

  @media (min-width: 992px) {
    margin-left: 0;
    display: block;
    font-size: rem(16px);
    padding: 6px 20px;
  }

  &.current {
    background-color: white;
  }
}

.br-secondary-menu {
  padding: 30px 0;

  @media (min-width: 992px) {
    display: none;
  }
}

.br-secondary-my-account {
  color: white;
  font-weight: bold;
  font-size: rem(24px);
  text-decoration: none;

  &.current {
    color: var(--color-text);
    background-color: #fff;
    padding: 6px 9px;
    border-radius: 3px;
  }
}
