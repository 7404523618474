.br-lightbox {
  position: fixed;

  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  padding-top: 10px;

  opacity: 0;
  pointer-events: none;
  top: 16px;

  transition: 200ms ease;

  &.active {
    top: 0;
    opacity: 1;
    pointer-events: all;
    transition: 300ms ease;
    z-index: 100;
  }

  @media (min-height: 568px) {
    padding-top: 36px;
  }
}

.br-lightbox__backdrop {
  background: rgba(12, 42, 60, 0.8);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.br-lightbox__container {
  position: relative;
  max-height: 75vh;

  @media (min-height: 568px) {
    max-height: 85vh;
  }

  @media (min-width: 1110px) {
    margin: 15px auto;
  }
}

.br-lightbox__close {
  display: block;
  margin-left: auto;
  cursor: pointer;
  background-color: transparent;
  border: none;
  padding: 0;
  width: 24px;
  height: 24px;
}

.br-lightbox__content {
  margin-top: 10px;
  background-color: white;
  max-height: 65vh;
  padding: 10px;
  border-radius: 1px;
  filter: drop-shadow(0px 0px 20px #051119);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  img {
    max-height: 60vh;

    @media (min-height: 568px) {
      max-height: 70vh;
    }
  }

  @media (min-height: 568px) {
    max-height: 75vh;
  }
}

.br-lightbox__btn {
  background-color: transparent;
  border: none;
  padding: 0;
  width: 40px;
  height: 40px;
  cursor: pointer;

  @media (min-width: 1040px) {
    position: absolute;
    left: -30px;
    top: 50%;
    transform: translateY(-50%);

    &--next {
      left: unset;
      right: -30px;
    }
  }

  &.disabled {
    cursor: not-allowed;
    opacity: 0.3;
    pointer-events: none;
  }
}
