/* Core components and dependencies */
@import "core/normalize";

// If we want to load stuff directly from node_modules, we can do so:
// @import "bootstrap/dist/css/bootstrap-grid.css";
// @import "bootstrap/dist/css/bootstrap-utilities.css";

// Otherwise, we can copy these compiled files to our project.
@import "core/bootstrap-grid";
@import "core/bootstrap-utilities";

@import "core/variables";
@import "core/helpers";
@import "core/core";

@import "layout/header";
@import "layout/footer";

@import "components/burger";
@import "components/input";
@import "components/dropdown";
@import "components/home-hero";
@import "components/home-deals";
@import "components/button";
@import "components/media";
@import "components/slider";
@import "components/form-block";
@import "components/banner";
@import "components/sidebar";
@import "components/drawer";
@import "components/checkbox";
@import "components/pagination";
@import "components/faq";
@import "components/product";
@import "components/lightbox";
@import "components/article";
@import "components/sidebar-menu";
@import "components/btn-top";
@import "components/404";
@import "components/auth";
@import "components/account";
@import "components/tab-block";
@import "components/filter-pill";
