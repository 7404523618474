.br-pagination {
  display: flex;
  list-style-type: none;
  align-items: center;
  justify-content: center;

  li {
    margin-right: 5px;
    width: 100%;

    @media (min-width: 768px) {
      width: auto;
    }

    a {
      text-decoration: none;
    }
  }

  li + li {
    margin-left: 5px;
  }
}

.br-pagination__num {
  display: none;
  @media (min-width: 768px) {
    display: block;
  }

  a {
    border-radius: 3px;
    background-color: white;
    color: #0c2a3c;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: rem(14px);
    width: 30px;
    height: 30px;

    &:hover {
      background-color: #afd6e9;
    }

    &:focus {
      outline: 0;
      box-shadow: inset 0 0 0 1px #2b94d2;
    }

    &:active {
      background-color: #2b94d2;
    }

    &.disabled,
    [disabled] {
      pointer-events: none;
      cursor: not-allowed;
      color: #c4c4c4;
    }
  }
}

.br-pagination__btn {
  text-align: center;
  font-size: rem(16px);
  font-weight: 800;
  background-color: var(--color-one);
  color: white;
  border-radius: 3px;
  padding: 6px 22px;
  display: block;

  &:focus {
    outline: 0;
    box-shadow: inset 0 0 0 1px #2b94d2;
  }

  &--next {
    background-color: #0c2a3c;
  }

  &.disabled,
  [disabled] {
    pointer-events: none;
    cursor: not-allowed;
    opacity: 0.6;
  }

  @media (min-width: 768px) {
    background-color: transparent;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: inherit;
  }
}
