.br-top {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  right: 165px;
  bottom: 60px;
  background: #2b94d2;
  box-shadow: 0px 0px 20px #61b7df;
  border-radius: 100px;
  z-index: 100;
  opacity: 0;
  pointer-events: none;
  transform: translateY(10px);
  transition: 200ms ease;

  @media (max-width: 991px) {
    display: none;
  }

  &:hover {
    background-color: #0b70ac;
    box-shadow: none;
  }

  &:focus {
    box-shadow: inset 0 0 0 1px #0c2a3c;
  }

  &:active {
    background-color: #065fab;
    box-shadow: none;
  }

  &.active {
    transform: translateY(0);
    opacity: 1;
    pointer-events: all;
    transition: 300ms ease;
    z-index: 100;
  }
}
