.br-checkbox {
  display: inline-flex;
  color: var(--color-text);
  font-size: rem(14px);
  align-items: center;
  line-height: 1.25;
  border-radius: 5px;
  user-select: none;

  @media (min-width: 992px) {
  }

  & > span {
    position: relative;
    display: flex;
    align-items: flex-start;
  }

  input[type="checkbox"] {
    position: absolute !important;
    height: 1px;
    width: 1px;
    overflow: hidden;
    clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
    clip: rect(1px, 1px, 1px, 1px);

    &:checked + span::after {
      background-color: #2b94d2;
    }
  }

  & > span::before {
    position: relative;
    content: "";
    display: inline-block;
    background-color: white;
    border: 1px solid #0c2a3c;
    margin-right: 10px;
    flex-shrink: 0;
    height: 10px;
    width: 10px;
    top: 2px;
  }

  input[type="checkbox"] {
  }

  & > span::after {
    content: "";
    display: inline-block;
    height: 6px;
    width: 6px;
    background-color: transparent;
    left: 2px;
    top: 4px;
    position: absolute;
  }
}
