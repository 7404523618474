.br-banner {
  position: relative;
  box-shadow: 0px 0px 40px #61b7df;
  border-radius: 5px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 150px;
  text-decoration: none;

  &:before {
    opacity: 0;
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: #2b94d2;
    mix-blend-mode: multiply;
    transition: 200ms ease;
  }

  @media (min-width: 992px) {
    height: 180px;
  }

  &:hover {
    box-shadow: none;
    &:before {
      opacity: 1;
    }
    .br-banner__img {
      filter: grayscale(1);
      transition: 200ms ease;
    }
  }
}

.br-banner__img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: 200ms ease;
}

.br-banner__text {
  color: white;
  font-weight: 800;
  font-size: rem(28px);
  z-index: 1;
  padding: 10px 15px;
  line-height: 1.25;

  @media (min-width: 992px) {
    font-weight: 700;
    font-size: rem(36px);
  }
}
