html {
  box-sizing: border-box;
  background-color: var(--color-bg);
  color: var(--color-text);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  scrollbar-color: #0c2a3c #e5f3fa;
  scrollbar-width: thin;

  scroll-behavior: smooth;
}

body::-webkit-scrollbar {
  width: 9px;
  height: 8px;
}

body::-webkit-scrollbar-track {
  background: #e5f3fa;
}

body::-webkit-scrollbar-thumb {
  background-color: #0c2a3c;
  outline: 0;
  border-radius: 9px;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

* {
  // outline-color: var(--color-three);
}

html,
body {
  height: 100%;
  padding: 0;
  margin: 0;
}

body {
  // display: grid;
  // grid-template-rows: auto 1fr auto;
  // grid-template-columns: minmax(0, 1fr);
  color: var(--color-text);
  font-family: var(--font-family);
}

details > * {
  box-sizing: border-box;
}

img {
  display: block;
  max-width: 100%;
  height: auto;
}

/* Bootstrap override */
@media (min-width: 1400px) {
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 1140px;
  }
}

/**
* List marker color.
* Currently works in Firefox and Safari
* ref: https://caniuse.com/#feat=css-marker-pseudo
*/
ul li::marker {
  // color: var(--color-accent);
}

ol,
ul {
  padding: 0;
  margin: 0;
}

ul {
  list-style: square;
}

/* font weights */

.br-font-100 {
  font-weight: 100;
}

.br-font-200 {
  font-weight: 200;
}

.br-font-300 {
  font-weight: 300;
}

.br-font-400,
.br-font-normal {
  font-weight: 400;
}

.br-font-500 {
  font-weight: 500;
}

.br-font-600 {
  font-weight: 600;
}

.br-font-700 {
  font-weight: 700;
}

.br-font-800 {
  font-weight: 800;
}

.br-font-900 {
  font-weight: 900;
}

/* Layout for filter column and main content */

.br-col-layout {
  display: flex;
  flex-direction: column;

  @media (min-width: 992px) {
    flex-direction: row;
    display: flex;
  }
}

.br-col-layout__sidebar {
  flex-grow: 0;
  flex-shrink: 0;

  @media (min-width: 992px) {
    width: 270px;

    margin-right: 0;
    margin-left: 30px;
  }
}

.br-col-layout__main {
  flex-grow: 1;
}

.br-col-layout.inverted-on-desktop {
  .br-col-layout__sidebar {
    margin-right: 20px;
    margin-left: 0;
  }

  .br-col-layout__main {
    flex-grow: 1;
    order: 0;

    @media (min-width: 992px) {
      order: 1;
    }
  }
}

// Alpinejs helper
[x-cloak] {
  display: none !important;
}

.br-flow > * + * {
  line-height: 1.5;
  margin-top: var(--flow-space, 1em);
}

/* Font sizes */
.br-section-title {
  font-size: rem(28px);
  font-weight: bold;
  display: flex;
  align-items: center;

  @media (min-width: 992px) {
    font-size: rem(36px);
  }
}

// Prose defines default text styles, for usage in default pages or default content
.br-prose {
  font-size: rem(14px);

  @media (min-width: 992px) {
    font-size: rem(16px);
  }

  h1 {
    font-weight: bold;
    font-size: rem(28px);

    @media (min-width: 992px) {
      font-size: rem(36px);
    }
  }

  h2 {
    font-weight: bold;
    font-size: rem(24px);
    line-height: 1.2;
    @media (min-width: 992px) {
      font-size: rem(30px);
    }
  }

  h3 {
    font-weight: bold;
    font-size: rem(20px);
    line-height: 1.2;
    @media (min-width: 992px) {
      font-size: rem(24px);
    }
  }

  h4 {
    font-weight: bold;
    font-size: rem(17px);
    line-height: 1.2;

    @media (min-width: 992px) {
      font-size: rem(18px);
    }
  }

  ul,
  ol {
    list-style: disc;
    margin-left: 1.5rem;
  }

  a {
    text-decoration: none;
    color: #2b94d2;
  }
}

.br-separator {
  border: none;
  border-bottom: 5px solid #0e3f5c;
  color: white;
  margin: 0;
  padding: 0;
}
