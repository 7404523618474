.br-filter-pills-list {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  margin-bottom: 10px;
}

.br-filter-pill {
  white-space: nowrap;

  display: inline-flex;
  align-items: center;
  border-radius: 3px;
  background-color: #eff9ff;
  color: #0c2a3c;
  font-size: 14px;
  line-height: 1.2;
  min-height: 26px;
  padding: 4px 8px;
  cursor: default;
  user-select: none;

  button {
    width: 18px;
    height: 18px;
    padding: 0;
    margin-right: 5px;
    background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.99956 7.93955L12.7121 4.22705L13.7726 5.28755L10.0601 9.00005L13.7726 12.7126L12.7121 13.7731L8.99956 10.0606L5.28706 13.7731L4.22656 12.7126L7.93906 9.00005L4.22656 5.28755L5.28706 4.22705L8.99956 7.93955Z' fill='%230C2A3C'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    cursor: pointer;
    border: 0;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom-left-radius: 3px;
    border-top-left-radius: 3px;
  }
}

.br-filter-pills-clear {
  display: inline-flex;
  cursor: pointer;
  align-items: center;
  min-height: 26px;
  padding: 4px 8px;
  padding-left: 26px;
  border-radius: 3px;
  border: 0;
  background-color: transparent;
  color: #2b94d2;
  font-size: 14px;
  background-position: center left 6px;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.99956 7.93955L12.7121 4.22705L13.7726 5.28755L10.0601 9.00005L13.7726 12.7126L12.7121 13.7731L8.99956 10.0606L5.28706 13.7731L4.22656 12.7126L7.93906 9.00005L4.22656 5.28755L5.28706 4.22705L8.99956 7.93955Z' fill='%232B94D2'/%3E%3C/svg%3E%0A");
}

.br-filter-pills {
  border-bottom: 5px solid #83c3e2;
  padding-bottom: 15px;
  margin-bottom: 15px;
}
