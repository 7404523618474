.br-account__avatar-wrap {
  width: 100px;

  .br-btn {
    position: relative;
  }
}

.br-account__avatar {
  width: 100px;
  height: 100px;
  overflow: hidden;
  border-radius: 5px;
  filter: drop-shadow(0px 0px 20px #61b7df);

  img {
    object-fit: center;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    position: absolute;
  }
}

.br-account__title {
  font-weight: bold;
  font-size: rem(28px);
}

.br-account__bio {
  font-size: rem(14px);
}

.br-account__fields {
}

.br-account__field-label {
  font-size: rem(20px);
  line-height: 1;
  font-weight: bold;
}

.br-account__field-help {
  color: #7d7d7d;
  font-size: rem(14px);
}
