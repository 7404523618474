.br-footer {
  background-color: var(--color-four);
  color: white;
}

.br-footer__title {
  text-transform: uppercase;
  color: white;
  font-weight: 900;
  font-size: rem(18px);
}

.br-footer__info {
  font-size: rem(12px);
  line-height: 1.4;

  a {
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
}

.br-footer__list {
  list-style: none;

  a {
    text-decoration: none;
    color: white;
    font-size: rem(16px);
    display: inline-block;
    margin: 4px 0;
    &:hover {
      text-decoration: underline;
    }
  }
}

.br-footer__copy {
  font-size: rem(12px);
}

.br-footer__logo {
  max-width: 100%;
  display: block;
  svg {
    max-width: 100%;
  }
}

// Extra footer

.br-footer-separator {
  border: none;
  border-bottom: 5px solid #0e3f5c;
  color: white;
}

.footer__section-title {
  font-size: rem(28px);
  font-weight: bold;

  @media (min-width: 992px) {
    font-size: rem(36px);
  }
}

.footer__intro-text {
  font-size: rem(14px);
  line-height: 1.5;
}
