:root {
  /* Colors */
  --color-one: #2b94d2;
  --color-two: #f7ad15;
  --color-three: #f74b15;
  --color-four: #0c2a3c;
  --color-text: #0c2a3c;
  --color-bg: #d5eaf6;
  --grey-one: #dadada;
  --grey-two: #e5e5e5;
  --grey-three: #f5f5f5;

  /* Typography */
  --font-family: "Nunito Sans", sans-serif;
  --font-family-headings: "Nunito Sans", sans-serif;
}
