@use "sass:math";

/* Functions */

@function rem($px) {
  $size: strip-unit($px);

  @return math.div($size, 16) + rem;
}

@function strip-unit($number) {
  @if type-of($number) == "number" and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

.br-color-main {
  color: var(--color-one);
}

.br-color-secondary {
  color: var(--color-two);
}

.br-bg-main {
  background-color: var(--color-one);
  color: white;
}

.br-bg-dark {
  background-color: var(--color-four);
}

// Default focus

@mixin focus-btn {
  box-shadow: 0 0 0 5px #e5e5e5;
  outline: 0;
}

// Apply default border-radius and border
@mixin default-border {
  box-shadow: inset 0 0 0 1px #e5e5e5;
  border-radius: 10px;
}

.br-color-two {
  color: var(--color-two);
}

@mixin scrollbars(
  $size,
  $foreground-color,
  $background-color: mix($foreground-color, white, 50%),
  $border-radius: 15px
) {
  //https://css-tricks.com/snippets/sass/custom-scrollbars-mixin/

  // For Google Chrome
  &::-webkit-scrollbar {
    width: $size;
    height: $size;
  }

  &::-webkit-scrollbar-thumb {
    background: $foreground-color;
    border: 3px solid $background-color;
    border-radius: $border-radius;
  }

  &::-webkit-scrollbar-track {
    background: $background-color;
  }

  & {
    scrollbar-color: $foreground-color $background-color;
    scrollbar-width: thin;
  }

  // For Internet Explorer
  & {
    scrollbar-face-color: $foreground-color;
    scrollbar-track-color: $background-color;
  }
}
