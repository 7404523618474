@use "sass:math";
/* Media item */

.br-media {
  background-color: white;
  border-radius: 5px;
  padding: 10px;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  transition: 200ms ease;

  .br-btn {
    display: block;
    width: 100%;
    margin-top: 4px;
  }

  &:hover {
    &:not(.br-media--mini) {
      box-shadow: 0px 0px 20px #99c6db;
    }

    .br-media__primary-info {
      color: #2b94d2;
    }
  }

  &--mini {
    &:hover {
      transform: translateY(-5px);
    }
  }
}

.br-media__primary-info {
  text-decoration: none;
  color: var(--color-text);
}

.br-media__bottom {
  margin-top: auto;
  width: 100%;
}

.br-media__thumbnail-link {
  &:focus {
    outline: 0;
    opacity: 0.9;
  }
}

.br-media__secondary-info {
  font-size: rem(10px);
  font-weight: normal;
  line-height: 1.2;
}

.br-media__info {
  margin-top: 5px;
  width: 100%;
}

.br-media__title {
  font-size: rem(12px);
  font-weight: bold;
}

.br-media__price {
  color: #2b94d2;
  font-weight: bold;
  font-size: rem(18px);
  margin-top: 6px;
  text-decoration: none;
  font-variant-numeric: tabular-nums;
  width: 100%;
}

.br-media-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(140px, 2fr));
  grid-column-gap: 10px;
  grid-row-gap: 10px;

  @media (min-width: 992px) {
    grid-column-gap: 30px;
    grid-row-gap: 30px;
    grid-template-columns: repeat(auto-fill, minmax(140px, 4fr));
  }

  @media (min-width: 1200px) {
    grid-template-columns: repeat(auto-fill, minmax(180px, 4fr));
  }
}

.br-media-grid-wide {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(140px, 2fr));
  grid-column-gap: 10px;
  grid-row-gap: 10px;

  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 18px;
    grid-row-gap: 18px;
  }

  @media (min-width: 1100px) {
    grid-template-columns: repeat(6, 1fr);
    grid-column-gap: 18px;
  }
}

.br-media-grid__banner {
  grid-area: 5 / 1 / 5 / 3;
  background-color: #0c2a3c;
  border-radius: 5px;
  min-height: 150px;

  @media (min-width: 992px) {
    grid-area: 4 / 1 / 4 / 5;
  }
}

// Aspect ratio stuff (not used right now)
.outer {
  position: relative;
  background: pink;
  &:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: math.div(49, 36) * 100%;
  }
  > .inner {
    background: gold;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    img {
      object-fit: contain;
    }
  }
}

// skeleton loaders

.br-skeleton {
  .br-media {
    .br-media__thumbnail-link {
      background-color: #d5eaf6;
      border-radius: 3px;
      img {
        opacity: 0;
      }
    }

    .br-media__secondary-info,
    .br-media__primary-info,
    .br-media__price,
    .br-btn {
      background-color: #d5eaf6;
      border-radius: 3px;
      color: #d5eaf6;
    }
  }
}
