.br-sidebar__title {
  font-size: rem(24px);
  font-weight: bold;
  border-bottom: 3px solid #99c6db;
  padding-bottom: 5px;
  line-height: 1.1;
  margin-bottom: 20px;
}

.br-small-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 2fr));
  grid-column-gap: 10px;
  grid-row-gap: 10px;

  @media (min-width: 992px) {
    grid-column-gap: 20px;
    grid-row-gap: 20px;
  }

  .br-media {
    padding: 5px;
  }
}

.br-sidebar-list {
  margin-left: 24px;
}

.br-sidebar-list-item {
  font-size: rem(14px);
  color: #0c2a3c;
  font-weight: bold;

  &:hover {
    &::marker {
      color: #0c2a3c;
    }
    a {
      color: var(--color-one);
    }
    color: var(--color-one);
  }

  a {
    display: block;
    color: #0c2a3c;
    text-decoration: none;
  }
}

.br-sidebar-list-item + .br-sidebar-list-item {
  margin-top: 10px;
}

.br-sidebar-list-item__price {
  color: #2b94d2;
  font-weight: normal;
  font-variant-numeric: tabular-nums;
}

.br-square-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  grid-column-gap: 10px;
  grid-row-gap: 10px;

  @media (min-width: 768px) {
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 35px;
    grid-row-gap: 35px;
  }

  .main-item {
    grid-area: 1 / 1 / 2 / 3;

    @media (min-width: 768px) {
      grid-area: 1 / 1 / 3 / 3;
    }
  }

  .br-media {
    justify-content: center;
  }

  .br-media__thumbnail {
    width: 100%;
  }
}
