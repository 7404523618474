// Newsletter block

.br-form-block {
  position: relative;
  background-color: #0c2a3c;
  color: white;
  padding: 15px;
  box-shadow: 0px 0px 20px #61b7df;
  border-radius: 5px;
  text-align: center;
  overflow: hidden;

  @media (min-width: 768px) {
    padding: 30px;
  }

  .overlay {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #0c2a3c;
    mix-blend-mode: hard-light;
  }

  &--bright {
    box-shadow: 0px 0px 10px #051119;
    background-color: #d5eaf6;
    padding: 15px;

    @media (min-width: 992px) {
      padding: 30px;
    }

    .br-form-block__title {
      color: #0c2a3c;
    }
  }
}

.br-form-block__title {
  font-size: rem(28px);
  font-weight: 800;
  line-height: 1.3;

  @media (min-width: 768px) {
    text-align: left;
    font-size: rem(36px);
    line-height: 1.1;
  }
}

.br-form-block__subtitle {
  font-size: rem(16px);

  @media (min-width: 768px) {
    text-align: left;
    font-size: rem(14px);
    line-height: 1.5;
  }
}

.br-form-block__input-group {
  @media (min-width: 768px) {
    text-align: right;
  }
}

.br-form-block__img {
  position: absolute;
  object-fit: cover;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.br-form-block__btn {
  @media (min-width: 992px) {
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: rem(18px);
  }
}
