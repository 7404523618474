.br-dropdown {
  cursor: pointer;
  position: relative;

  svg {
    transition: 100ms ease;
  }

  &.active {
    svg {
      transform: rotate(180deg);
    }

    .br-dropdown__options {
      display: block;
      z-index: 12;
    }
  }

  &--flags {
    .br-dropdown__option {
      justify-content: space-between;
    }
  }
}

.br-dropdown__btn {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: rem(16px);
  border: none;
  background-color: transparent;
  color: white;

  span {
    margin-right: 10px;
  }
}

.br-dropdown__options {
  display: none;
  list-style: none;
  padding: 9px 6px;
  position: absolute;
  top: 100%;

  min-width: 70px;
  background: #ffffff;
  box-shadow: 0px 0px 10px #61b7df;
  border-radius: 3px;

  @media (min-width: 992px) {
    right: 0;
  }
}

.br-dropdown__option {
  color: #0c2a3c;
  padding: 4px 9px;
  border-radius: 3px;
  display: flex;
  align-items: center;

  &.selected,
  &:hover {
    color: white;
    background-color: #2b94d2;
  }
}
