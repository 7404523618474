$bar-width: 40px;
$bar-height: 3px;
$bar-spacing: 10px;
$button-padding: 4px;

.btn-burger {
  z-index: 10;
  position: absolute;
  left: 15px;
  top: 28px;
  width: $bar-width + $button-padding;
  border: none;
  background: transparent;
  cursor: pointer;
  padding: 0;
  border-radius: 5px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: $bar-spacing * 2 + $bar-height * 3 + $button-padding;

  @media (min-width: 992px) {
    display: none;
  }

  &:focus {
    outline: 0;
  }

  // Custom styles
  margin-right: 25px;
}

.burger-menu {
  position: absolute;
  background-color: white;
  margin: auto;
  width: $bar-width;
  height: $bar-height;
  cursor: pointer;
  z-index: 100;
  border: none;
  outline: none;

  .menu-open & {
    background-color: transparent;

    .bar:after {
      top: 0;
      transform: rotate(45deg);
      transition: top 100ms cubic-bezier(0.23, 1, 0.32, 1),
        transform 100ms 100ms cubic-bezier(0.23, 1, 0.32, 1);
      background: white;
    }

    .bar:before {
      bottom: 0;
      transform: rotate(-45deg);
      transition: bottom 100ms cubic-bezier(0.23, 1, 0.32, 1),
        transform 100ms 100ms cubic-bezier(0.23, 1, 0.32, 1);
      background: white;
    }
  }
}

.bar,
.bar:after,
.bar:before {
  width: 100%;
  height: $bar-height;
}

.bar {
  background: white;
  transition: all 0ms 100ms;

  &.active {
    background: transparent;
  }
}

.bar:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: $bar-spacing;
  background: white;
  transition: bottom 100ms 100ms cubic-bezier(0.23, 1, 0.32, 1),
    transform 100ms cubic-bezier(0.23, 1, 0.32, 1);
}

.bar:after {
  content: "";
  position: absolute;
  left: 0;
  top: $bar-spacing;
  background: white;
  transition: top 100ms 100ms cubic-bezier(0.23, 1, 0.32, 1),
    transform 100ms cubic-bezier(0.23, 1, 0.32, 1);
}
