.br-btn {
  cursor: pointer;
  background-color: #f7ad15;
  border-radius: 3px;
  font-size: rem(16px);
  color: #0c2a3c;
  font-weight: 800;
  text-align: center;
  padding: 8px 22px;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: 200ms ease;
  border: none;

  &:hover {
    background-color: #ffcf00;
  }

  &:focus {
    box-shadow: inset 0 0 0 1px #0c2a3c;
    outline: 0;
  }

  &:active {
    background-color: #ffde88;
  }

  &:disabled,
  .disabled {
    background-color: #f2f2f2;
    color: #afafaf;
    outline: 0;
    box-shadow: 0;
  }

  &--simple {
    background-color: white;
    padding: 8px 22px;

    &:hover {
      background-color: white;
      opacity: 0.9;
    }

    &:active {
      background-color: white;
    }
  }
}

.br-btn-action {
  cursor: pointer;
  background-color: #2b94d2;
  border-radius: 3px;
  font-size: rem(16px);
  color: white;
  font-weight: normal;
  text-align: center;
  padding: 5px 22px;
  min-height: 30px;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: 200ms ease;
  border: none;

  &:hover {
    background-color: #0b70ac;
  }

  &:focus {
    box-shadow: inset 0 0 0 1px #0c2a3c;
    outline: 0;
  }

  &:active {
    background-color: #065fab;
  }

  &:disabled,
  .disabled {
    background-color: #f2f2f2;
    color: #afafaf;
    outline: 0;
    box-shadow: 0;
  }
}
