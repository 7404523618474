summary {
  font-size: rem(20px);
  font-weight: bold;
  list-style-type: none;
  position: relative;
  padding: 0.85em 40px 0.85em 0;
  line-height: 1;
  cursor: pointer;

  &:focus {
    outline: none;
  }
}

.summary__body {
  line-height: 1.5;
  font-size: rem(14px);

  p {
    margin: 1em 0;
  }

  a {
    color: #2b94d2;
    text-decoration: none;
  }
}

summary:after {
  height: 24px;
  width: 24px;
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.0003 10.8278L7.05026 15.7778L5.63626 14.3638L12.0003 7.99983L18.3643 14.3638L16.9503 15.7778L12.0003 10.8278Z' fill='white'/%3E%3C/svg%3E%0A");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  content: " ";
  float: none;
  display: block;
  transition: 100ms ease;
}

details[open] summary:after {
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.9997 13.1722L16.9497 8.22217L18.3637 9.63617L11.9997 16.0002L5.63574 9.63617L7.04974 8.22217L11.9997 13.1722Z' fill='white'/%3E%3C/svg%3E%0A");
}

summary::-webkit-details-marker {
  display: none;
}

.faq-banner {
  overflow: hidden;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  font-size: rem(24px);
  font-weight: bold;
  position: relative;
  box-shadow: 0 0 40px 0 #051119;
  min-height: 190px;
  max-width: 220px;
  margin-left: auto;
  text-decoration: none;

  span {
    z-index: 1;
    padding: 0 15px 12px 15px;
    margin-top: auto;
    color: white;
  }

  img {
    position: absolute;
    object-fit: cover;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }
}
